<template>
  <div class="login">
    <div class="pb-3 login-user-logo">
      <img src="@/assets/images/UserLOGO.svg" alt="logo" />
    </div>
    <div class="card login-card">
      <div class="card-body p-4">
        <div class="login-logo">
          <img src="@/assets/images/Logo.svg" alt="logo" />
        </div>
        <form @submit.prevent="submitForm">
          <div class="text-danger text-center" v-if="is_invalid">
            {{ feedback_message }}
          </div>
          <button
            type="submit"
            class="btn btn-primary rounded-pill login-submit mt-3"
          >
            {{ $t(`__login`) }}
          </button>
        </form>
      </div>
    </div>
    <small class="p-3 d-block text-center text-dark fw-light">
      © {{ new Date().getFullYear() + " " + $t("__HumetricsInc") }} All
      rights reserved.
    </small>
  </div>
</template>

<script>
import { auth } from '@/http/api/auth.js'
import { mapMutations, mapActions } from 'vuex'

export default {
  name: 'LoginWithToken',
  data () {
    return {
      feedback_message: null,
      is_invalid: false,
      bootstrap_popover: null
    }
  },
  created () {
    this.submitForm()
  },
  methods: {
    ...mapActions(['getUserInfo']),
    ...mapMutations(['Loaded', 'Loading']),
    submitForm () {
      const vm = this
      vm.Loading()

      const token = vm.$route.params.token

      auth
        .loginWithToken(token)
        .then((res) => {
          const feaback = res.data
          if (res.status <= 201) {
            if (feaback.status === 'success') {
              localStorage.setItem('humetrics_user_token', feaback.token)
              if (feaback.passwordExpire) {
                vm.$router.push({ name: 'Change Password' })
              } else {
                vm.$router.push({ name: 'Dashboard' })
              }
            } else {
              vm.is_invalid = true
              vm.feedback_message = feaback.errMsg
            }
          } else {
            vm.is_invalid = true
            vm.feedback_message = feaback.errMsg
          }
          vm.Loaded()
        })
        .catch((err) => {
          vm.is_invalid = true
          vm.feedback_message = err.message || 'An error occurred'
          vm.Loaded()
        })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss">
.login {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  &-user-logo {
    max-width: 150px;
    width: 40%;
    margin: auto;
    img {
      width: 100%;
    }
  }
  &-logo {
    max-width: 250px;
    width: 70%;
    margin: 20px auto;
    img {
      width: 100%;
    }
  }
  &-forgot-password {
    text-decoration: none;
    font-size: 14px;
    &:focus {
      box-shadow: none;
    }
  }
  &-card {
    max-width: 360px;
    width: 90%;
    margin: auto;
    border-radius: 10px;
  }
  &-submit {
    width: 100%;
    color: $white;
    transition: box-shadow 0.3s ease-in-out;
    background: $linear-gradient;
    box-shadow: 0px 4px 4px $shadow-color;
    &:hover {
      color: $white;
      box-shadow: 0px 7px 7px $shadow-color;
    }
    &:focus {
      color: $white;
    }
  }
  .show-password{
    background-color: #fff;
    border: none;
    color: $gray;
  }
}
</style>
